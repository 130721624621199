import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import linkIcon from '../img/link-icon.svg'
import { useAppSelector } from '../redux/hooks'

function SimplifiedReturnInfo(): JSX.Element {
  const textProperties = useAppSelector((state) => state.textProperties.value)

  return (
    <Col xs={12} className="py-3">
      <Row className="py-2">
        <Col xs={12}>
          <div className="d-flex align-items-center mb-2">
            <h2>{textProperties.returnRegisteredHeader}</h2>
          </div>
          <p>{textProperties.returnRegisteredInfo}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={9}>
          <a
            href={textProperties.onlineStoreReturnPolicyUrl}
            className="btn btn-primary font-weight-bold pl-lg-5 pr-3 py-2 d-flex justify-content-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            {textProperties.returnInfoLink}
            <img src={linkIcon} alt="link-icon" className="px-2" />
          </a>
        </Col>
      </Row>
    </Col>
  )
}

export default SimplifiedReturnInfo
