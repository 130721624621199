import Button from 'react-bootstrap/Button'
import { friendlyFormatIBAN, isValidIBAN } from 'ibantools'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import CalculationFunctions from '../helpers/calculationFunctions'
import { IDraftReturnLine, IDraftReturnStartItem } from '../models/om-models'
import { TextProperties } from '../models/text-properties-model'
import { useAppSelector } from '../redux/hooks'
import { DraftReturnLine } from './Product'

type ReturnSummaryProps = {
  bankAccountNumberRequired: boolean | undefined
  setSendDataHandler: (value?: string) => void
  draftReturnLines: DraftReturnLine[]
  items: IDraftReturnStartItem[] | undefined
}

function ReturnSummary(props: ReturnSummaryProps): JSX.Element {
  const {
    bankAccountNumberRequired,
    setSendDataHandler,
    draftReturnLines,
    items,
  } = props

  const [bankAccountNo, setBankAccountNo] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(!bankAccountNumberRequired)

  const [confirmRequestInFlight, setConfirmRequestInFlight] =
    useState<boolean>(false)
  const textProperties = useAppSelector((state) => state.textProperties.value)

  const productsConfirmed = draftReturnLines.some((drl) => drl.confirmed)

  const generateReturnData = (): void => {
    setConfirmRequestInFlight(true)
    setSendDataHandler(bankAccountNo)
  }

  const formatIBAN = (value: string): void => {
    const trimmedValue = value.replace(/ /g, '')
    setBankAccountNo(trimmedValue)
    validateIBAN(trimmedValue)
  }

  const validateIBAN = (value: string): void => {
    setIsValid(isValidIBAN(value))
  }

  const confirmationNotification = !productsConfirmed ? (
    <p>{textProperties.productConfirmNotification}</p>
  ) : undefined

  const confirmButtonEnabled =
    !confirmRequestInFlight &&
    isValid &&
    draftReturnLines.length > 0 &&
    productsConfirmed

  return (
    <div className="mb-5 p-4 beige-box return-summary">
      <Row>
        <Col xs={12}>
          <p className="font-weight-bold mb-0">
            {textProperties.youAreReturning}
          </p>
          <p className="font-weight-bold">
            <ReturnQuantityLabel
              draftReturnLines={draftReturnLines}
              items={items}
              textProperties={textProperties}
            />
          </p>
          <p>{textProperties.summaryInfo}</p>
          {confirmationNotification}
          {bankAccountNumberRequired ? (
            <div>
              <p className="font-weight-bold">{textProperties.accountNumber}</p>

              <Form>
                <Form.Group controlId="formIBAN">
                  <Form.Control
                    type="text"
                    placeholder="FI1234567898765432"
                    value={friendlyFormatIBAN(bankAccountNo) as string}
                    aria-label="iban-input"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      formatIBAN(e.target.value)
                    }
                  />
                  {bankAccountNo?.length > 0 && !isValid ? (
                    <div className="error small mt-1" aria-label="iban-error">
                      {textProperties.bankAccountNumberIsNotValid}
                    </div>
                  ) : null}
                  <Form.Text className="text-muted">
                    {textProperties.giveIBAN}
                  </Form.Text>
                </Form.Group>
              </Form>
            </div>
          ) : null}
          <Button
            onClick={generateReturnData}
            disabled={!confirmButtonEnabled}
            className={`btn btn-primary mt-3 py-2 w-100 font-weight-bold ${
              confirmButtonEnabled ? '' : 'disabled'
            }`}
          >
            {textProperties.confirmReturn}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

function ReturnQuantityLabel(props: {
  draftReturnLines: IDraftReturnLine[]
  items: IDraftReturnStartItem[] | undefined
  textProperties: TextProperties
}): JSX.Element {
  const { draftReturnLines, items, textProperties } = props
  const quantity = draftReturnLines.reduce((sum, line) => {
    return line.quantity + sum
  }, 0)
  const quantityLabel =
    quantity === 1 ? textProperties.oneProduct : textProperties.multipleProducts

  const returnAmount: number = draftReturnLines.reduce(
    (sum, currentDraftReturnLine) => {
      if (items) {
        const line = items.find(
          (l) => l.itemId === currentDraftReturnLine.itemId,
        )
        if (line) {
          const price = +CalculationFunctions.calculateRetailPriceForQuantity(
            line,
            currentDraftReturnLine.quantity,
          )
          return sum + price
        }
        return 0
      }
      return 0
    },
    0,
  )

  const fixedPrice = returnAmount.toFixed(2).replace('.', ',')

  return (
    <span>
      {quantity}
      {quantityLabel}({fixedPrice} €).
    </span>
  )
}

export default ReturnSummary
