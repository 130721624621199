import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CustomerServiceInfo from '../components/CustomerServiceInfo'
import textProperties from '../helpers/commonTextProperties'
import { IDraftReturnStartResponseWrapper } from '../models/om-models'
import ErrorInfo from '../components/ErrorInfo'
import HelpBox from '../components/HelpBox'
import OrderNumberInfo from '../components/OrderNumberInfo'

type StartViewProps = {
  orderNumberAvailable: boolean
  data: IDraftReturnStartResponseWrapper | undefined
}

function StartView(props: StartViewProps): JSX.Element {
  const { orderNumberAvailable, data } = props
  return (
    <div>
      <Row className="mb-3">
        <Col>
          <h1 data-testid="heading">{textProperties.onlineStoreItemReturn}</h1>
        </Col>
      </Row>
      <Row>
        {orderNumberAvailable ? <OrderNumberInfo data={data} /> : <ErrorInfo />}
        <Col xs={12} sm={5}>
          <HelpBox />
          <CustomerServiceInfo />
        </Col>
      </Row>
    </div>
  )
}

export default StartView
