import { CustomTextProperties } from '../models/text-properties-model'

const textProperties: CustomTextProperties = {
  startPageInfo:
    'Voit palauttaa yhden tai useamman tuotteen tilaukseltasi, kun tuotteet ovat käyttämättömiä ja myyntikuntoisia. Prisman verkkokaupasta ostetuilla tuotteilla on 30 päivän palautusoikeus.\n' +
    '\n' +
    'Verkkokaupan palautusoikeus ei koske seuraavia tuotteita:\n' +
    '- Tyynyjä ja peittoja sekä avattuja hygienia- ja intiimituotteita, kuten seksivälineet, kasvomaskit, epilaattorit, partakoneet, hiustenhoitolaitteet ja -leikkurit, sähköhammasharjat ja suuhygienialaitteet, kuume- ja verenpainemittarit, nappikuulokkeet ja hands-free-laitteet.\n' +
    '- Pelejä, elokuvia, ohjelmistoja, kosmetiikkaa tai muita sinetöityjä tuotteita, kun tuotepakkaus tai sinetti on avattu.\n' +
    '- Digitaalisia tuotteita, kuten e-kirjoja ja äänikirjoja.\n' +
    '- Asiakkaalle leikattua metritavaraa kuten puutavaraa tai kankaita.\n' +
    '- Sekoitettuja maaleja.',
  frontPage: 'Prisma.fi etusivu',
  summaryInfo:
    'Kun vahvistat palautuksen, Prisma saa tiedon tulevasta palautuksesta.',
  toStore: 'Prisma.fi etusivulle',
  storeUrl: 'https://www.prisma.fi',
  frequentlyAskedUrl: 'https://www.prisma.fi/tuki/usein-kysytyt-kysymykset',
  onlineStoreReturnPolicyUrl:
    'https://www.prisma.fi/fi/prisma/palauttaminen#palautusEhdot',
  returnRegisteredInfo:
    'Seuraavaksi voit palauttaa tuotteet meille samalla tavalla, kuin millä ne toimitettiin sinulle. Jos sait tilauksesi useana lähetyksenä, palauta tuotteet kunkin lähetyksen toimitustavan mukaan.',
  reclamationsUrl: 'https://www.prisma.fi/fi/prisma/asiakaspalvelu/reklamaatio',
  termsOfUseUrl:
    'https://www.prisma.fi/fi/prisma/verkkokauppa/verkkopalvelun-kayttoehdot',
  shippingAndDeliveryUrl:
    'https://www.prisma.fi/fi/prisma/verkkokauppa/tilaus-ja-toimitusehdot',
  privacyNoticeUrl:
    'https://www.prisma.fi/fi/prisma/verkkokauppa/tietosuoja-ja-turvallisuus',
  customerServiceUrlLabel: 'Katso asiakaspalvelun yhteystiedot ja aukioloajat',
  customerServiceUrl: 'https://www.prisma.fi/tuki/yhteystiedot',
  customerServiceOpeningTimes: '',
}

export default textProperties
